import firebase from 'firebase/compat/app';
import { getAnalytics } from "firebase/analytics";
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/functions';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyCBTG_7tArH8_4pKf0mau2mj7BOefVuFHc",
  authDomain: "miwebz-web.firebaseapp.com",
  projectId: "miwebz-web",
  storageBucket: "miwebz-web.appspot.com",
  messagingSenderId: "3566602641",
  appId: "1:3566602641:web:b2b0844f91f7e5104ff230",
  measurementId: "G-FM212RVKEW"
};

  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  
  //const db = firebase.firestore()
  const auth = firebase.auth();
  const db = firebase.firestore();
  const storage = firebase.storage();
  const app = firebase.app()
  const analytics = getAnalytics(app);

export { firebase, auth, db, storage, app, analytics };