<template>
  <div id="app" class="h-screen">
    <router-view />
  </div>
  
</template>
<script>
  export default {
    data() {
      return {
        versionAlert: {
          show: true,
          deploy: true
        }
      }
    },
    watch: {
      // Set meta tags
      $route(to) {
        this.setMeta(to.meta);
      },
    },
    components: {
    },
    methods: {
      closeAlert() {
        this.versionAlert.show = false
      },
      setMeta(meta) {
        document.title = meta.pageTitle;
        if(meta.title === undefined)
        {
          document.title = 'miwebz.net - Profesjonalne strony internetowe, sklepy internetowe, aplikacje mobilne i wiele więcej!';
        }
      }
    },
  }
</script>
<style>

* {
    -webkit-tap-highlight-color: transparent;
}

.fade-enter-active,
.fade-leave-active {
transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
opacity: 0;
}

/* width */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
  
/* Track */
::-webkit-scrollbar-track {
    background: rgb(209, 213, 219);
    border-radius: 8px;
}
  
/* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(126, 131, 143);
    border-radius: 8px;
}
  
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

</style>
