import { createStore } from 'vuex'
import { auth, db} from '@/firebase/gfbconf'
import axios from 'axios';

export default createStore({
  state: {
    apiLink: "production",
    notification: {
      show: false,
      head: "",
      subheader: "",
      success: true,
    },
    user: null,
    userData: null,
    authIsReady: false,
    globalLoader: {
      show: true,
      head: "Loading..",
      subheader: "Don't close this tab."
    }
  },
  mutations: {
    setNotification(state, notification)
    {
      state.notification = notification;
      setTimeout(()=>
      {
        state.notification = {
          show: false,
          head: "",
          subheader: "",
          success: true,
        }
      },3000)
    },
    setUrl(state, url)
    {
      state.url = url
    },
    // Set user to Vuex store
    setUser(state, userData)
    {
      state.user = userData;
    },
    setUserCredentials(state, userData)
    {
      state.userData = userData;
    },
    setAuthIsReady(state,payload)
    {
      state.authIsReady = payload;
    },
    // reset global loader
    resetGlobalLoader(state)
    {
      state.globalLoader = {
        show: false,
        head: "Ładowanie..",
        subheader: "Nie zamykaj tego okna."
      }
    },
    setGlobalLoader(state,context)
    {
      state.globalLoader = context;
    },

  },
  actions: {
  },
  modules: {
  }
})
